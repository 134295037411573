<template>
  <div>
    <div :class="`${node.type == 'group' ? 'node-hover' : ''}`">
      <div
          :style="{'margin-left': 25 * depth + 'px'}"
      >
        <div class="d-flex justify-content-between">
          <div  class="node" @click="expanded = !expanded">
            <span v-if="hasChildren">{{ expanded ? '&#9660' : '&#9658'}}</span>
            <span v-if="node.type == 'group'">{{ node.name }}</span>
          </div>
          <div v-if="type == 'group'" class="d-flex">
            <div  class="dropdown w-30">
              <button type="button" class="w-44 btn btn-sm dropdown-toggle py-0" data-bs-toggle="dropdown">
                <img src="/app-assets/images/icons/plus-solid.svg" alt="">
              </button>
              <div class="dropdown-menu dropdown-menu-end">
                <a @click.prevent="onClickAddGroupBtn" v-if="depth < 3" class="dropdown-item" href="#">
                  <span>Add sub Group</span>
                </a>
                <a @click.prevent="onClickAddLocationBtn(node)" class="dropdown-item" href="#">
                  <span>Add Head</span>
                </a>
              </div>
            </div>
            <div class="w-20 z-999"></div>
            <div>
              <img
                  @click="emitGroupEdit"
                  width="13"
                  class="cursor-pointer"
                  src="/app-assets/images/icons/pencil-alt-solid.svg"
                  alt=""
              >
            </div>
          </div>
        </div>
        <!-- <span>{{depth}} {{node.type}}</span>  -->
        <div
            class="d-flex cursor-initial justify-content-between bg-black-light rounded-8 p-1 mb-1"
            v-if="node.type == 'endpoint'"
        >
          <p class="mb-0">{{ node.name }}</p>
          <div class="d-flex">
            <p class="mb-0 me-1">{{ node.total }}</p>
            <img @click="emitLocationEdit" width="13" class="cursor-pointer" src="/app-assets/images/icons/pencil-alt-solid.svg" alt="">
          </div>
        </div>
      </div>
    </div>
    <hr class="mb-1 mt-0" v-if="type == 'group'">
    <template v-if="expanded">
      <InvestigationTree
          v-for="item in node.child"
          :key="item.name"
          :parent="node"
          :depth="depth + 1"
          :node="item"
      />
    </template>
  </div>
</template>

<script>
export default {
  name: 'InvestigationTree',
  data () {
    return {
      expanded: true
    }
  },
  computed: {
    hasChildren () {
      return this.node.child.length
    },
    type () {
      return this.node.type
    },
    parentL () {
      return this.parent.child && this.parent.child.length ? this.parent.child.length : 0
    },
    last () {
      return this.parent.child ? this.parent.child[this.parentL-1] : {}
    },
    isLast () {
      if(this.last.id) {
        return this.last.id == this.node.id
      }
      return false
    }
  },
  methods: {
    toggleParent () {
      this.isParentOpen = !this.isParentOpen
    },
    onClickAddGroupBtn() {
      this.emitter.emit('onClickAddGroup', this.node)
    },
    onClickAddLocationBtn(data) {
      this.emitter.emit('onClickAddLocation', data)
    },
    emitGroupEdit() {
      this.emitter.emit('onGroupUpdate', {
        eventName: 'onUpdateGroup',
        title: 'Edit group',
        data: this.node
      })
    },
    emitLocationEdit() {
      this.emitter.emit('onLocationUpdate', {
        eventName: 'onUpdateLocation',
        title: 'Edit location',
        data: this.node
      })
    },

  },
  props: {
    node: Object,
    depth: {
      type: Number,
      default: 0
    },
    parent: {
      default: []
    }
  }
}
</script>

<style scoped>
.bg-black-light {
  background: #00000024;
}
.rounded-8 {
  border-radius: 8px;
}
.node {
  cursor: pointer;
}
.node-hover {
  padding: 10px 2px;
}
.node-hover:hover {
  background: #fffeec;
}
.cursor-initial {
  cursor: initial;
}
.cursor-pointer {
  cursor: pointer;
}
.w-30 {
  width: 30px;
}
.w-20 {
  width: 20px;
}
.z-999 {
  z-index: 999;
}
.w-44 {
  width: 44px;
}
</style>
