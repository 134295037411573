<template>
    <div class="card p-2" style="min-height: calc(100vh - 185px);">
      <div class="d-flex flex-wrap justify-content-between align-item-center">
        <h3>Investigation Head</h3>
        <div class="gx-1">
          <button
              style="min-width: 64px;"
              @click="openGroupAddModal"
              class="btn btn-primary waves-effect waves-float waves-light me-1"
          >Add Group
          </button>
          <button
              style="min-width: 64px;"
              @click="openLocationAddModal"
              class="btn btn-primary waves-effect waves-float waves-light"
          >Add Investigation
          </button>
        </div>
      </div>
      <div class="mt-2"></div>
      <Tab
          @onClickTab="onClickTab"
          :tabs="homeContent.investigationHeadTypes"
          routeName="investigation-heads"
      />
      <hr>
      <TreeBrowser
          :style="{opacity: chartLoading ? .5 : 1}"
          v-for="(item, i) in investigationList"
          :key="i"
          :node="item"
      />
      <p v-if="!investigationList.length" class="text-center mt-5">No data</p>
  
      <GroupAddModal
          modalTitle="Add Group"
          ref="group"
          :type="homeContent.investigationHeadTypes"
          :formData="groupAddData"
          :status="homeContent.status"
          :group="investigationHeadGroup"
          @onSubmitGroup="handleGroupSubmit"
      />
      <GroupAddModal
          submitEvent="onUpdateGroup"
          :showParent="true"
          modalTitle="Edit Group"
          ref="groupedit"
          :type="homeContent.investigationHeadTypes"
          :formData="groupEditData"
          :status="homeContent.status"
          :group="investigationHeadGroup"
          @onUpdateGroup="handleGroupUpdate"
      />
  
      <InvestigationAddModal
          :showParent="true"
          modalTitle="Add Investiation Head"
          ref="location"
          :type="homeContent.investigationHeadTypes"
          :sampleTypes="homeContent.sampleTypes"
          :testMethods="homeContent.testMethods"
          :reports="homeContent.reports"
          :formData="locationAddData"
          :status="homeContent.status"
          :group="investigationHeadGroup"
          @onSubmitLocation="handleInvestigationSubmit"
      />
      <InvestigationAddModal
          submitEvent="onUpdateLocation"
          :showParent="true"
          modalTitle="Edit Investiation Head"
          ref="locationedit"
          :type="homeContent.investigationHeadTypes"
          :sampleTypes="homeContent.sampleTypes"
          :testMethods="homeContent.testMethods"
          :reports="homeContent.reports"
          :product="product"
          :formData="locationEditData"
          :status="homeContent.status"
          :group="investigationHeadGroup"
          @onUpdateLocation="handleInvestigationUpdate"
      />
  
      <Loader v-if="loading"/>
    </div>
  </template>
  
  <script>
  import handleInventory from '@/services/modules/inventory';
  import handleHospital from '@/services/modules/hospital';
  import {inject}        from 'vue'
  
  import TreeBrowser      from '@/components/molecule/company/hospital/investigation-head/InvestigationTree'
  import Tab              from '@/components/atom/Tab'
  import TitleButton      from '@/components/atom/TitleButton'
  import Loader           from '@/components/atom/LoaderComponent'
  import GroupAddModal    from '@/components/molecule/company/hospital/investigation-head/GroupAddModal'
  import InvestigationAddModal from '@/components/molecule/company/hospital/investigation-head/InvestigationAddModal'
  
  export default {
    name: 'InvestigationHeadList',
  
    components: {
      TitleButton,
      TreeBrowser,
      Tab,
      Loader,
      GroupAddModal,
      InvestigationAddModal
    },
  
    data: () => ({
      loading              : false,
      investigationHeadGroup: [],
      investigationList         : [],
      accountHeads         : [],
      isGroup              : false,
      parentGroup          : '',
      homeContent          : {},
      groups               : [],
      groupAddData         : {
        name         : '',
        investigation_type: null,
        status       : 'active',
        company_id   : '',
        head_group_id: null
      },
      groupEditData        : {},
      locationAddData      : {
        name         : '',
        investigation_head : null,
        code         : '',
        investigation_type: null,
        has_discharge_certificate: false,
        status       : 'active',
        unit         : '',
        reference_value : '',
        sample       : null,
        test_method  : null,
        company_id   : '',
        head_group_id: null,
        product_id   : null,
        short_description : '',
        full_description  : '',
        default_result: ''
      },
      locationEditData     : {},
      product: null,
    }),
  
    computed: {
      companyId() {
        return this.$route.params.companyId
      },
      locationType() {
        return this.$route.params.accountType
      }
    },
  
    methods: {
      openGroupAddModal() {
        this.groupAddData.investigation_type = this.locationType
        this.$refs.group.toggleModal()
      },
  
      openLocationAddModal() {
        this.locationAddData.investigation_type = this.locationType
        this.$refs.location.toggleModal()
      },
  
      onClickTab() {
        this.getInvestigationList(this.accountType)
        this.getGroup()
      },
  
      async getHomeContent() {
        const query = `?company_id=${this.companyId}`;

        try {
          this.chartLoading = true
          let res = await this.fetchInvestigationHeadTypes(query)
          if (!res.status) {
            this.showError(res.message)
          }
          if (res.status) {
            this.homeContent = res.data
          }
        } catch (err) {
          if (!err.response) {
            this.showError('Something is wrong. Check your connectivity!!')
          }
          if (err.response) {
            this.showError(err.response.message)
          }
        } finally {
          this.chartLoading = false
        }
      },
  
      async getInvestigationList() {
        const query = `?company_id=${this.companyId}&investigation_type=${this.$route.params.accountType}`
        try {
          this.loading = true
          let res      = await this.fetchInvestigationHeadList(query)
          if (!res.status) {
            this.investigationList = []
          }
          if (res.status) {
            this.investigationList = res.data
          }
        } catch (err) {
          if (!err.response) {
            this.showError('Something is wrong. Check your connectivity!!')
          }
          if (err.response) {
            this.showError(err.response.message)
          }
        } finally {
          this.loading = false
        }
      },
  
      async getGroup() {
        try {
          this.loading = true
          let res      = await this.fetchInvestigationHeadGroup(this.companyId, this.locationType)
          if (!res.status) {
            this.investigationHeadGroup = []
          }
          if (res.status) {
            this.investigationHeadGroup = res.data
          }
        } catch (err) {
          if (!err.response) {
            this.showError('Something is wrong. Check your connectivity!!')
          }
          if (err.response) {
            this.showError(err.response.message)
          }
        } finally {
          this.loading = false
        }
      },
  
      async handleGroupUpdate() {
        // return;
        try {
          this.$refs.groupedit.loading  = true
          this.groupEditData.company_id = this.companyId
          // have to refactor
          let res = await this.updateInvestigationHeadGroup(this.groupEditData)
          if (!res.status) {
            this.showError(res.message)
          }
          if (res.status) {
            this.$refs.groupedit.toggleModal()
            this.getGroup()
            this.getInvestigationList(this.accountType)
            this.showSuccess(res.message)
          }
        } catch (err) {
          console.log(err);
          if (!err.response) {
            this.showError('Something is wrong. Check your connectivity!!')
          }
          if (err.response) {
            this.showError(err.response.message)
          }
        } finally {
          this.$refs.groupedit.loading = false
        }
      },

      async handleGroupSubmit() {
        try {
          this.$refs.group.loading     = true
          this.groupAddData.company_id = this.companyId
          // have to refactor
          let res = await this.storeInvestigationHeadGroup(this.groupAddData)
          if (!res.status) {
            this.showError(res.message)
          }
          if (res.status) {
            this.$refs.group.toggleModal()
            this.getGroup()
            this.getInvestigationList(this.accountType)
            this.fet
            this.groupAddData = {
              name         : '',
              investigation_type: null,
              status       : 'active',
              company_id   : '',
              head_group_id: null
            }
            this.showSuccess(res.message)
          }
        } catch (err) {
          if (!err.response) {
            this.showError('Something is wrong. Check your connectivity!!')
          }
          if (err.response) {
            this.showError(err.response.message)
          }
        } finally {
          this.$refs.group.loading = false
        }
      },
  
      async handleInvestigationUpdate() {
        try {
          this.$refs.locationedit.loading  = true
          this.locationEditData.company_id = this.companyId
          // have to refactor
          let res = await this.updateInvestigationHead(this.locationEditData)
          if (!res.status) {
            this.showError(res.message)
          }
          if (res.status) {
            this.$refs.locationedit.toggleModal()
            this.getGroup()
            this.getInvestigationList(this.accountType)
            this.showSuccess(res.message)
          }
        } catch (err) {
          console.log(err);
          if (!err.response) {
            this.showError('Something is wrong. Check your connectivity!!')
          }
          if (err.response) {
            this.showError(err.response.message)
          }
        } finally {
          this.$refs.locationedit.loading = false
        }
      },
      async handleInvestigationSubmit() {
        try {
          this.$refs.location.loading     = true
          this.locationAddData.company_id = this.companyId
          // have to refactor
          let res = await this.storeInvestigationHead(this.locationAddData)
          if (!res.status) {
            this.showError(res.message)
          }
          if (res.status) {
            this.$refs.location.toggleModal()
            this.getGroup()
            this.getInvestigationList(this.accountType)
            this.fet
            this.locationAddData = {
              name         : '',
              code         : '',
              investigation_type: null,
              status       : 'active',
              company_id   : '',
              head_group_id: null,
              investigation_head : null,
              has_discharge_certificate: false,
              unit         : '',
              reference_value : '',
              sample       : null,
              test_method  : null,
              product_id   : null,
              short_description : '',
              full_description  : '',
              default_result: ''
            }
            this.showSuccess(res.message)
          }
        } catch (err) {
          if (!err.response) {
            this.showError('Something is wrong. Check your connectivity!!')
          }
          if (err.response) {
            this.showError(err.response.message)
          }
        } finally {
          this.$refs.location.loading = false
        }
      },
  
      async getInitialData() {
        const query  = `?company_id=${this.companyId}&investigation_type=${this.$route.params.accountType}`
        this.loading = true
        
        await Promise.all([
          this.fetchInvestigationHeadGroup(this.companyId, this.locationType)
              .then(res => {
                    if (res.data) this.investigationHeadGroup = res.data
              }),

          this.fetchInvestigationHeadList(query)
              .then(res => {
                  if (res.data) this.investigationList = res.data
              }),
        ])
          .then(res => {
            this.loading = false
          })
          .catch(error => {
            this.loading = false
          });
      },
    },
  
    setup() {
      const showError   = inject('showError');
      const showSuccess = inject('showSuccess');

      const {
        fetchInvestigationHeadTypes,
        fetchInvestigationHeadList,
        fetchInvestigationHeadGroup,
        storeInvestigationHeadGroup,
        updateInvestigationHeadGroup,
        storeInvestigationHead,
        updateInvestigationHead
      } = handleHospital();

      const { chartCreateLoading, chartLoading } = handleInventory();
  
      return {
        chartCreateLoading,
        chartLoading,
        fetchInvestigationHeadTypes,
        showError,
        showSuccess,
        fetchInvestigationHeadGroup,
        fetchInvestigationHeadList,
        storeInvestigationHeadGroup,
        updateInvestigationHeadGroup,
        storeInvestigationHead,
        updateInvestigationHead,
      }
    },
  
    mounted() {
      const groupCreateModal  = this.$refs.group
      const groupEditModal    = this.$refs.groupedit
      const locationAddModal  = this.$refs.location
      const locationEditModal = this.$refs.locationedit
  
      this.getInitialData()
      this.getHomeContent()
  
      this.emitter.on('onClickAddGroup', (evt) => {
        this.groupAddData.investigation_type = this.locationType
        this.groupAddData.head_group_id = evt.id
        groupCreateModal.toggleModal();
      })
  
      this.emitter.on('onGroupUpdate', (evt) => {
        let data           = {
          id           : evt.data.id,
          name         : evt.data.name,
          investigation_type: evt.data.investigation_type,
          status       : 'active',
          company_id   : this.companyId,
          head_group_id: evt.data.investigation_head_id,
          _method      : 'PATCH'
        }
        this.groupEditData = data
        this.showParent    = true
        groupEditModal.toggleModal();
      })
  
      this.emitter.on('onClickAddLocation', (evt) => {
        this.locationAddData.investigation_type = this.locationType
        this.locationAddData.head_group_id = evt.id
        locationAddModal.toggleModal();
      })
  
      this.emitter.on('onLocationUpdate', (evt) => {
        let data              = {
          id           : evt.data.id,
          name         : evt.data.name,
          code         : evt.data.code,
          investigation_type: evt.data.investigation_type,
          status       : 'active',
          company_id   : this.companyId,
          head_group_id: evt.data.investigation_head_id,
          investigation_head : evt.data.investigation_head,
          has_discharge_certificate: evt.data.has_discharge_certificate,
          unit         : evt.data.unit,
          reference_value : evt.data.reference_value,
          sample       : evt.data.sample,
          test_method  : evt.data.test_method,
          product_id   : evt.data.product_id,
          short_description : evt.data.short_description,
          full_description  : evt.data.full_description,
          default_result: evt.data.default_result
        }
        this.locationEditData = data
        this.product = evt.data.product;
        this.showParent       = true
        locationEditModal.toggleModal();
      })
    }
  }
  </script>
  