<template>
  <div v-if="isOpen" class="modal fade show" aria-labelledby="addNewCardTitle"
       style="display: block; background: #1c1d1c33">
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="row">
          <div class="col-12">
            <div class="d-flex justify-content-between px-1 my-1 align-item-center">
              <h1 class="mb-0">{{ modalTitle }}</h1>
              <button
                  @click="toggleModal"
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
              ></button>
            </div>
          </div>
        </div>
        <hr class="mt-0">

        <div class="pb-2">
          <!-- form -->
          <vForm @submit="submit" :validation-schema="schema">
            <div class="row gy-2 px-2">
              <div class="col-md-6">
                  <label class="form-label">Category</label>
                  <v-select
                      v-model="formData.investigation_type"
                      label="name"
                      :options="typeArr"
                      :reduce="name => name.id"
                  />
              </div>

              <div class="col-md-6 discharge-cert">
                <div class="form-check">
                  <input v-model="formData.has_discharge_certificate" class="form-check-input" id="remember-me" type="checkbox" tabindex="3"/>
                  <label class="form-check-label" for="remember-me"> Discharge Certificate?</label>
                </div>
              </div>

              <div class="col-md-6">
                <label class="form-label" for="chartName">Investigation Head</label>
                <div class="input-group">
                  <vField
                      type="text"
                      name="name"
                      v-model="formData.name"
                      class="form-control add-credit-card-mask"
                      placeholder="Investigation Head"
                  />
                  <ErrorMessage name="name" class="invalid-feedback d-block qcont"/>
                </div>
              </div>

              <div class="col-md-6">
                  <label class="form-label">Select Service Name</label>
                  <AsyncSelect
                      placeholder="Select Service Name"
                      v-model="formData.product_id"
                      :api-service="fetchProductList"
                      :reduce="name => name.id"
                      :format-label="option => option.text"
                      :additional-query="{product_type: 'services'}"
                      :additional-option="additionalOptions"
                      label="text"
                  />
              </div>

              <div v-if="showParent" class="col-md-6">
                <label class="form-label">Parent Group</label>
                <v-select
                    v-model="formData.head_group_id"
                    label="name"
                    :options="group"
                    :reduce="name => name.id"
                />
              </div>

              <div class="col-md-6">
                <label class="form-label" for="chartName">Unit</label>
                <div class="input-group">
                  <vField
                      type="text"
                      name="unit"
                      v-model="formData.unit"
                      class="form-control add-credit-card-mask"
                      placeholder="Unit"
                  />
                  <ErrorMessage name="unit" class="invalid-feedback d-block qcont"/>
                </div>
              </div>

              <div class="col-md-6">
                <label class="form-label" for="chartName">Investigation Report Name</label>
                <v-select
                    v-model="formData.investigation_head"
                    label="name"
                    :options="reportsArr"
                    placeholder="Investigation Report Name"
                    :reduce="name => name.id"
                />
              </div>

              <div class="col-md-6">
                <label class="form-label" for="chartName">Reference Value</label>
                <div class="input-group">
                  <textarea 
                    v-model="formData.reference_value"
                    name="reference" rows="2"
                    placeholder="Reference Value"
                    class="form-control add-credit-card-mask"
                  >
                  </textarea>
                </div>
              </div>

              <div v-if="showParent" class="col-md-6">
                <label class="form-label">Sample</label>
                <v-select
                    v-model="formData.sample"
                    label="name"
                    :options="sampleArr"
                    placeholder="Select sample"
                    :reduce="name => name.id"
                />
              </div>

              <div v-if="showParent" class="col-md-6">
                <label class="form-label">Test Method</label>
                <v-select
                    v-model="formData.test_method"
                    label="name"
                    :options="testMethodArr"
                    placeholder="Select test method"
                    :reduce="name => name.id"
                />
              </div>

              <div class="col-md-6">
                <label class="form-label" for="chartName">Code</label>
                <div class="input-group">
                  <vField
                      type="text"
                      name="code"
                      v-model="formData.code"
                      class="form-control add-credit-card-mask"
                      placeholder="Code"
                  />
                  <ErrorMessage name="code" class="invalid-feedback d-block qcont"/>
                </div>
              </div>

              <div class="col-md-6">
                <label class="form-label" for="chartName">Default Value</label>
                <div class="input-group">
                    <textarea 
                      v-model="formData.default_result"
                      name="reference" rows="2"
                      placeholder="Default value"
                      class="form-control add-credit-card-mask"
                    >
                    </textarea>
                </div>
              </div>

              <div class="col-12">
                <label class="form-label" for="Full">Short Description</label>
                <textarea 
                    v-model="formData.short_description"
                    name="reference" rows="2"
                    placeholder="Short description"
                    class="form-control add-credit-card-mask"
                  >
                  </textarea>
              </div>

              <div class="col-12">
                <label class="form-label" for="Full">Full Description</label>
                <textarea 
                    v-model="formData.full_description"
                    name="reference" rows="2"
                    placeholder="Full description"
                    class="form-control add-credit-card-mask"
                  >
                  </textarea>
              </div>
            </div>

            <div class="col-12 text-center">
              <hr class="mt-2">
              <button :disabled="loading" type="submit"
                      class="btn btn-primary me-1 mt-1 waves-effect waves-float waves-light">
                <div v-if="loading" class="spinner-border spinner-border-sm text-light" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
                Submit
              </button>
              <button @click.prevent="toggleModal" class="btn btn-outline-secondary mt-1 waves-effect">
                Cancel
              </button>
            </div>
          </vForm>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import objToArray from '@/services/utils/object-to-array'
import AsyncSelect from "@/components/molecule/input-field/AsyncSelect.vue";
import handlePurchase from '@/services/modules/purchase'
const {fetchProductList} = handlePurchase()

export default {
  name: 'InvestigationAddModal',

  data: () => ({
    isOpen : false,
    loading: false,
    schema : {
      name: 'required|min:3|max:100',
    }
  }),

  components: {
    AsyncSelect
  },

  computed: {
    additionalOptions(){
        if (!this.product) return [];

        return [
            {
                id: this.product.id,
                text: this.product.name,
            }
        ]
    },
    statusArr() {
      return Object.keys(this.status)
    },
    typeArr() {
      return objToArray(this.type)
    },
    sampleArr() {
      return objToArray(this.sampleTypes)
    },
    testMethodArr() {
      return objToArray(this.testMethods)
    },
    reportsArr() {
      return objToArray(this.reports)
    }
  },

  methods: {
    fetchProductList,
    submit() {
      this.$emit(this.submitEvent)
    },
    toggleModal() {
      this.isOpen = !this.isOpen
      if (!this.isOpen) {
        this.$emit("onCloseLocationModal")
      }
    }
  },

  props: {
    formData   : Object,
    product: Object | null,
    showParent : {
      type   : Boolean,
      default: false
    },
    type       : Object,
    group      : Array,
    sampleTypes : {
      type: Object,
      default: {},
    },
    testMethods : {
      type: Object,
      default: {},
    },
    reports : {
      type: Object,
      default: {},
    },
    status     : Object,
    modalTitle : {
      type   : String,
      default: 'Add Investigation'
    },
    submitEvent: {
      default: 'onSubmitLocation'
    }
  }
}
</script>

<style scoped>
  .discharge-cert{
    display: flex;
    align-items: center;
  }
</style>
