<template>
  <div v-if="isOpen" class="modal fade show" aria-labelledby="addNewCardTitle" style="display: block; background: #1c1d1c33">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="row">
          <div class="col-12">
            <div class="d-flex justify-content-between px-1 my-1 align-item-center">
              <h1 class="mb-0">{{ modalTitle }}</h1>
              <button
                  @click="toggleModal"
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
              ></button>
            </div>
          </div>
        </div>
        <hr class="mt-0">

        <div class="pb-2">
          <!-- form -->
          <vForm @submit="submit" :validation-schema="schema">
            <div class="row gy-2 px-2">
              <div class="col-12">
                <label class="form-label" for="chartName">Name</label>
                <div class="input-group">
                  <vField
                      type="text"
                      name="name"
                      v-model="formData.name"
                      class="form-control add-credit-card-mask"
                      placeholder="Name"
                  />
                  <ErrorMessage name="name" class="invalid-feedback d-block qcont"/>
                </div>
              </div>

              <div v-if="showParent" class="col-md-12">
                <label class="form-label">Parent Group</label>
                <v-select
                    v-model="formData.head_group_id"
                    label="name"
                    :options="group"
                    :reduce="name => name.id"
                />
              </div>

              <div class="col-md-12">
                <label class="form-label">Type</label>
                <v-select
                    v-model="formData.investigation_type"
                    label="name"
                    :options="typeArr"
                    :reduce="name => name.id"
                />
              </div>

              <!-- <div class="col-md-12">
                  <label class="form-label">Status</label>
                  <v-select
                      v-model="formData.status"
                      :options="statusArr"
                  />
              </div> -->
            </div>

            <div class="col-12 text-center">
              <hr class="mt-2">
              <button :disabled="loading" type="submit" class="btn btn-primary me-1 mt-1 waves-effect waves-float waves-light">
                <div v-if="loading" class="spinner-border spinner-border-sm text-light" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
                Submit
              </button>
              <button @click.prevent="toggleModal" class="btn btn-outline-secondary mt-1 waves-effect">
                Cancel
              </button>
            </div>
          </vForm>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import objToArray from '@/services/utils/object-to-array'

export default {
  name: 'NewGroup',

  data: () => ({
    isOpen: false,
    loading: false,
    schema: {
      name: 'required|min:3|max:100',
    }
  }),

  computed: {
    statusArr() {
      return Object.keys(this.status)
    },
    typeArr() {
      return objToArray(this.type)
    }
  },

  methods: {
    submit () {
      this.$emit(this.submitEvent)
    },
    toggleModal () {
      this.isOpen = !this.isOpen
      if(!this.isOpen) {
        this.$emit("onCloseGroupModal")
      }
    }
  },

  props: {
    formData: Object,
    showParent: {
      type: Boolean,
      default: false
    },
    type: Object,
    group: Array,
    status: Object,
    modalTitle: {
      type: String,
      default: 'Add Group'
    },
    submitEvent: {
      default: 'onSubmitGroup'
    }
  }
}
</script>
